.payment {
  form {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }

  .loading {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 100px;
    font-size: xxx-large;
  }

  .payment-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }

  .payment-container {
    margin: 20px;
  }

  .payment-line {
    margin-top: 10px;
    border-bottom: 1px solid rgb(166, 166, 166);
  }

  .payment-total {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
    
    > div {
      min-width: 210px;
    }
  }

  .payment-comment {
    margin: 20px 0;
    display: flex;
    justify-content: flex-end;
  }

  .payment-total-line {
    display: flex;
    justify-content: space-between;
  }

  .payment-taxes {
    font-size: 12px;
    margin-left: 15px;
    display: flex;
    justify-content: space-between;
  }

  .payment-promoCode {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    align-items: center;

    button {
      margin-right: 10px;
    }
  }

  h3 {
    margin-top: 20px;
  }

    
  .cart-message {
      font-weight: bold;
      color: red;
  }


}

.payment-modal {
    padding: 20px;
    text-align: center;
    
    .loading {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
}

