.orders {
  min-height: calc(100vh - 150px);

  .orders-title {
    margin: 15px;
    display: flex;
    justify-content: space-between;
  }

  .orders-info {
    margin-right: 15px;
  }

  .orders-container {
    margin: 0 20px;
  }

  .orders-detailIcon {
    display: flex;
    align-items: center;

    .disabled i {
      color: #b2b2b2;
    }
    
    i {
      color: var(--primary);
      font-size: 20px;
    }
  }

  .orders-detailList {
    display: none;
  }

  .orders-detailRow {
    display: flex;
    justify-content: space-between;
  }

  .orders-loading {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 500px;

    i {
      color: var(--primary);
      font-size: 5em;
    }
  }
}

